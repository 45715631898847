import * as React from "react"
import Layout from "../../components/Layout"
import { Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import ImageDownload from "../../components/ImageDownload"

const IndexPage = () => {
    const root = "/img/download/awards/partners"

    return (
        <Layout pageTitle="Awards 2021 - Partners">
            <Row>
                <Col>
                    <Link to="/awards">Back</Link>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col md={8}>
                    <p className="para">
                        Use these badges and images to shout about the Women's
                        Business Awards and the part you are playing in
                        supporting and elevating hard working business women,
                        and women in the workplace.
                    </p>
                    <p className="para">
                        Badges can be used on social media posts, website, email
                        footer etc.
                    </p>
                    <p className="para">
                        Social media images can be shared far and wide wherever
                        you are present on social media! Make sure you tag
                        @wombizclub #WBCONF2023 #WBAWARDS2023 and link to
                        https://awards.womensbusiness.club
                    </p>
                </Col>
            </Row>
            <ImageDownload
                root={root}
                name="Awards Partner Badge"
                filename="awards-partner-badge"
                squareHref="https://www.canva.com/design/DAFQtthF69w/I3HbKASEUjJ8f095OC00qw/view?utm_content=DAFQtthF69w&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                text={`Proud partner of the Women's Business Awards - acknowledging and celebrating women's professional achievements across the globe https://awards.womensbusiness.club\n\n@wombizclub #WBCONF2022 #WBAWARDS2022`}
            />
            <ImageDownload
                root={root}
                name="Nomination Countdown"
                filename="ten-days-sme"
                horizontalHref="https://www.canva.com/design/DAFGe66jsa4/F2_OJTPqghVpSUEdoWkjtg/view?utm_content=DAFGe66jsa4&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                text={`There are just __ days left to get involved in the Women’s Business Awards. \n\n We’re so proud to be partnering with the Women’s Business Conference & Awards to empower and celebrate phenomenal women in business this October. \n\n Do you know a hard working female founder or top-level female executive who deserves some recognition?! Someone who champions women in the workplace, takes CSR seriously, does business sustainably or shows excellence in customer service? \n\n Maybe they deserved to be recognised for their incredible start up journey, or for all they've achieved at a young age? If you think someone, or even yourself, deserves to be recognised and celebrated - take 10 mins to nominate them today! \n\n  https://womensbusiness.club/awards/sme \n\n #WBCONF2023 #WBAWARDS2023`}
            />
            
            <Row className="mt-5">
                <Col md={6}>
                    <img
                        src={`/img/wbc-conference-html-email.png`}
                        className="img-fluid"
                        alt=""
                        style={{ maxHeight: "350px" }}
                    />
                </Col>
                <Col>
                    <h3>Email Templates</h3>
                    
                    <a
                        href="https://docs.google.com/document/d/1IjERZMgUDizSYbeKJA_0iMl18u71NVaKy15lkEq3o_k/edit?usp=sharing"
                        className="btn btn-primary m-3"
                    >
                        #1 Conference Introduction
                    </a>
                    <a
                        href="https://docs.google.com/document/d/1qmrQJmPGrrHZh6S0RDQNHuR1a1G-OvatbOM-63b-eno/edit?usp=sharing"
                        className="btn btn-primary m-3"
                    >
                        #2 10 Days to Go!
                    </a>
                </Col>
            </Row>
        </Layout>
    )
}

export default IndexPage
